import type { RawParams, UIRouter } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { ICentralFeedListResponse } from 'api/feed/central-feed/types';

import { selectStateDeclarations } from 'store/application/selectors';
import type { IApplicationUser } from 'store/application';
import type { IRootStore } from 'store/index';

import type { IControllerVM } from '../../controllers/types';

import { LayoutType } from 'settings/consts';

import settingsParams from './settingsParams';

export function setupRouter(
  params: ControllerParams,
  vm: IControllerVM,
  router: UIRouter,
  store: IRootStore,
) {
  const { isSSR } = params.flowAPI.environment;
  const { settings } = params.flowAPI;

  const states = selectStateDeclarations(store.getState());

  router.stateRegistry.deregister('groups');

  router.stateRegistry.register({
    ...states.groups,
    resolve: [
      {
        token: 'isLoggedIn',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          return user.loggedIn;
        },
      },
      {
        token: 'isSideBarLayout',
        resolveFn() {
          return (
            settings.get(settingsParams.groupListLayout) === LayoutType.sideBar
          );
        },
      },
      {
        token: 'commentsApi',
        async resolveFn() {
          if (!isSSR) {
            await vm._.comments.init();
          }
        },
      },
      {
        token: 'joinedGroups',
        deps: ['isSideBarLayout', 'isLoggedIn'],
        async resolveFn(isSideBarLayout: boolean, isLoggedIn: boolean) {
          if (!isSideBarLayout || !isLoggedIn) {
            return;
          }

          return vm.groups$.queryJoined();
        },
      },
      {
        token: 'centralFeed',
        deps: ['isSideBarLayout', 'isLoggedIn'],
        async resolveFn(isSideBarLayout: boolean, isLoggedIn: boolean) {
          const isPublicFeedEnabled = params.flowAPI.experiments.enabled(
            'specs.groups.EnablePublicFeed',
          );
          if (!isSideBarLayout || (!isPublicFeedEnabled && !isLoggedIn)) {
            return;
          }
          return vm.centralFeed$.fetch(undefined, false);
        },
      },
      {
        token: 'comments',
        deps: ['centralFeed', 'commentsApi'],
        async resolveFn(centralFeed?: ICentralFeedListResponse) {
          if (!isSSR && centralFeed) {
            await vm._.comments.fetch(centralFeed.items);
          }
        },
      },
    ],
    async onEnter(transition) {
      if (!isSSR) {
        await transition.injector().getAsync('commentsApi');
        vm._.comments.bind();
      }
    },
  });
}
