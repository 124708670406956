export const RTL_LANGUAGES = [
  'ar',
  'arc',
  'bcc',
  'bqi',
  'ckb',
  'dv',
  'fa',
  'glk',
  'ha',
  'he',
  'khw',
  'ks',
  'ku',
  'mzn',
  'pnb',
  'ps',
  'sd',
  'ug',
  'ur',
  'yi',
];

export const checkRTL = (language: string) => RTL_LANGUAGES.includes(language);
