import { GroupsController } from './GroupsController';
import { Controller } from '../../../common/controllers/groups-list/Controller';
import {
  AppToastsController,
  MemberInvitesController,
} from '../../../common/controllers';
import { GroupsPageProps } from '../types';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { GroupRequestsController } from './GroupRequestsController';

export class GroupsPageController extends Controller<GroupsPageProps> {
  protected readonly groupsController!: GroupsController;

  constructor(controllerContext: ControllerParams) {
    super(controllerContext);
    this.groupsController = new GroupsController(controllerContext);
    this.controllers.push(
      this.groupsController,
      new GroupRequestsController(controllerContext),
      new AppToastsController(controllerContext),
      new MemberInvitesController(controllerContext),
    );
  }
}
